import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { ReactNode } from 'react'

import { useLinguiInit } from 'modules/i18n/hooks/useLinguiInit'
import { useLoadEnglishSync } from 'modules/i18n/hooks/useLoadEnglishSync'

import { useSyncLangHTMLAttr } from './hooks/useSyncLangHTMLAttr'

export const I18nProviderWrapper = ({ children }: { children: ReactNode }) => {
  useLoadEnglishSync()
  useLinguiInit()
  useSyncLangHTMLAttr()
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
