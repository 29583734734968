import { debounce, isEqual } from 'lodash'

import { getStore } from 'modules/redux'

import {
  OfflineManagerState,
  loadOfflineManagerState,
  selectOfflineManagerState,
} from './reducer'

const OFFLINE_STATE_KEY = 'offline-state-v1'

const PERSIST_DEBOUNCE = 500

/**
 * This function:
 * 1. Loads the persisted state from local storage
 * 2. setups redux observation to persist to local storage
 * 3. guarantees that the redux state is now up to date and the source
 * of truth
 * 4. returns a function to cleanup the persistence
 */
export const setupOfflineManagerPersistence = (
  store: ReturnType<typeof getStore>
): (() => void) => {
  const persistedState = loadPersistedState()
  let lastState: OfflineManagerState | null = null
  if (persistedState) {
    console.debug(
      'offlineMode - [setupOfflineManagerPersistence] LOADING PERSISTED STATE',
      persistedState
    )
    store.dispatch(loadOfflineManagerState(persistedState))
    lastState = persistedState
  }

  const persistDebounced = debounce((state: OfflineManagerState) => {
    persistState(state)
  }, PERSIST_DEBOUNCE)

  return store.subscribe(() => {
    const currentState = selectOfflineManagerState(store.getState())

    if (lastState === currentState) {
      return
    }

    if (isEqual(lastState, currentState)) {
      lastState = currentState
      return
    }

    lastState = currentState
    console.debug(
      'offlineMode - [setupOfflineManagerPersistence] PERSISTING STATE',
      currentState
    )
    persistDebounced(currentState)
  })
}

const loadPersistedState = (): OfflineManagerState | null => {
  try {
    const persistedState = localStorage.getItem(OFFLINE_STATE_KEY) as string
    return JSON.parse(persistedState)
  } catch (e) {
    return null
  }
}

const persistState = async (value: OfflineManagerState) => {
  try {
    const serialized = JSON.stringify(value)
    localStorage.setItem(OFFLINE_STATE_KEY, serialized)
  } catch (e) {
    console.error('Error persisting state', e)
  }
}
