import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { config } from 'config'
/**
 * To use datadog logging you must import the datadog log function and call directly
 * this should be used for specific logging + debugging and not capture all logs in client
 *
 * Ex:
 * ```
 * import { datadogLogs } from '@datadog/browser-logs'
 * datadogLogs.logger.error('jordan App loaded')
 * ```
 */
export function setupDatadogLogs() {
  datadogLogs.init({
    clientToken: config.DATADOG_CLIENT_TOKEN,
    service: 'gamma-client',
    env: config.APPLICATION_ENVIRONMENT.replace('production', 'prod'),
    site: 'datadoghq.com',
    // dont forward errors
    forwardErrorsToLogs: false,
    // dont forward consoles
    forwardConsoleLogs: [],
    sessionSampleRate: 100,
  })
}

export function setupDatadogRum() {
  const apiHostRegex = new RegExp(String.raw`^${config.API_HOST}`)
  if (config.DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: config.DATADOG_APP_ID,
      clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'app',
      env: config.APPLICATION_ENVIRONMENT.replace('production', 'prod'),
      sessionSampleRate: config.DATADOG_RUM_SAMPLE_RATE ?? 10,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        {
          match: apiHostRegex,
          propagatorTypes: ['tracecontext'],
        },
      ],
    })
  }
}
