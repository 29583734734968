import { memoize } from 'lodash'

import { getStore } from 'modules/redux'

import { serwistDisabled } from '../reducer'
import { OfflineCache } from './OfflineCache'

export class SerwistOfflineCache implements OfflineCache<any> {
  private store = getStore()

  constructor() {}

  async enable(): Promise<void> {
    try {
      // @ts-ignore - there have been issues with including the types for `@serwist/next/typings`
      // when included at the global level they mess up other packages.  When including
      // via <reference types="..." /> dependency cruiser complains about it being unreachable
      // so the solution now is to just ignore, serwist.register() is the only method we call
      // right now
      await window.serwist.register()
    } catch (error) {
      console.error('Error registering Serwist service worker:', error)
    }
  }

  async disable(): Promise<void> {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const registration of registrations) {
        console.debug(
          'offlineMode - [SerwistOfflineCache] Unregistering service worker:',
          registration
        )
        await registration.unregister()
      }
    } catch (error) {
      console.error('Error unregistering Serwist service worker:', error)
    }

    try {
      await this.clearCaches()
    } catch (e) {
      console.error('Error clearing caches', e)
    }
    this.store.dispatch(serwistDisabled())
  }

  async debug(): Promise<any> {
    const cacheKeys = await window.caches.keys()
    const cache = (
      await Promise.all(
        cacheKeys.map(async (key) => {
          const value = await window.caches
            .open(key)
            .then((cache) => cache.matchAll())
          return [key, value]
        })
      )
    ).reduce((acc, curr) => {
      acc[curr[0] as string] = curr[1]
      return acc
    }, {})

    return {
      cache,
    }
  }

  async gc(_expiry: number): Promise<void> {
    // this no-ops because the TTL mechanism for serwist
    // works well already
  }

  private async clearCaches() {
    try {
      if (!window.caches) {
        return
      }
      const names = await window.caches.keys()
      for (const name of names) {
        await window.caches.delete(name)
      }

      console.debug(
        'offlineMode - [SerwistOfflineCache] Serwist caches cleared successfully'
      )
    } catch (e) {
      console.error('Error clearing caches', e)
    }
  }
}

export const getSerwistOfflineCache = memoize(() => new SerwistOfflineCache())
