import { useCallback, useRef } from 'react'

import { config } from 'config'

import { ConnectionEvents } from '../ConnectionEventEmitter'
import { useOnConnectionEvent } from './connectionHooks'

const DEBUG_BACKGROUND_TIME = new URLSearchParams(
  config.IS_CLIENT_SIDE ? window.location.search : ''
).get('MAX_BACKGROUND_TIME_ALLOWED')

const MAX_BACKGROUND_TIME_ALLOWED =
  Number(DEBUG_BACKGROUND_TIME) || 1000 * 60 * 60 * 12 // 12 hours in ms

export const useRefreshOnForeground = () => {
  const isRefreshingRef = useRef(false)

  const callback = useCallback((payload: ConnectionEvents['foreground']) => {
    if (!payload.timeInBackground) {
      // this occurs when foreground is called but no background event had happened
      return
    }

    if (isRefreshingRef.current) {
      // dont try to ever refresh more than once
      return
    }

    if (payload.timeInBackground > MAX_BACKGROUND_TIME_ALLOWED) {
      isRefreshingRef.current = true
      window.location.reload()
    }
  }, [])

  useOnConnectionEvent('foreground', callback)
}
