import { memo } from 'react'

import { useApiRefetch } from 'modules/api/hooks/useApiRefetch'

import { useEmitConnectionEvents } from './hooks/connectionHooks'
import { useRefreshOnForeground } from './hooks/useRefreshOnBackground'

export const ConnectionMonitor = memo(() => {
  useEmitConnectionEvents()
  useApiRefetch()
  useRefreshOnForeground()

  return null
})

ConnectionMonitor.displayName = 'ConnectionMonitor'
