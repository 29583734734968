import { ConnectionEventEmitter } from 'modules/connection/ConnectionEventEmitter'
import { featureFlags } from 'modules/featureFlags'
import { getStore } from 'modules/redux'
import { apiFetchUser } from 'modules/user/api/fetchUser'

import {
  ApolloOfflineCache,
  getApolloOfflineCache,
} from './caches/ApolloOfflineCache'
import { OfflineCache } from './caches/OfflineCache'
import {
  SerwistOfflineCache,
  getSerwistOfflineCache,
} from './caches/SerwistOfflineCache'
import {
  YDocOfflineCache,
  getYDocOfflineCache,
} from './caches/YDocOfflineCache'
import { isOfflineModeSupported } from './compat'
import { setupOfflineManagerPersistence } from './persistence'
import {
  disableOfflineMode,
  enableOfflineMode,
  selectOfflineModeEnabled,
} from './reducer'

const IS_SUPPORTED = isOfflineModeSupported()

class OfflineManager {
  private store = getStore()

  public readonly serwistOfflineCache: SerwistOfflineCache =
    getSerwistOfflineCache()
  public readonly ydocOfflineCache: YDocOfflineCache = getYDocOfflineCache()
  public readonly apolloOfflineCache: ApolloOfflineCache =
    getApolloOfflineCache()

  public readonly caches: OfflineCache<any>[] = [
    this.serwistOfflineCache,
    this.ydocOfflineCache,
    this.apolloOfflineCache,
  ]

  private cleanupFns: (() => void)[] = []

  get enabled() {
    return selectOfflineModeEnabled(this.store.getState())
  }

  async init() {
    if (typeof window === 'undefined') {
      // we're on server, return dont log anything
      return
    }
    if (!IS_SUPPORTED) {
      console.debug(
        'offlineMode - [OfflineManager] Offline mode is not supported'
      )
      return
    }

    this.cleanupFns.push(setupOfflineManagerPersistence(this.store))

    /**
     * init() follows a slightly different flow than enable() and disable()
     * once the app is bootstrapped
     *
     * init() looks at the redux state to determine if offline mode is enabled
     * if it is, it will enable it
     * if it is not, it will disable it
     *
     * enable() and disable() are called by either a user interaction or
     * a feature flag change.  They will enable or disable all of the caches
     * and then update the redux state
     *
     * During init() the redux state is already correct and we are enabling/disabling
     * the caches to reflect that state
     */

    await this.notifyCaches(this.enabled)

    console.debug(
      'offlineMode - [OfflineManager] caches initialized',
      this.enabled
    )
    this.setupFeatureFlagSubscription()
    this.setupConnectionListener()

    console.debug('offlineMode - [OfflineManager] subscriptions initialized')
  }

  setupConnectionListener() {
    const offlineCleanup = ConnectionEventEmitter.on('offline', () => {
      this.apolloOfflineCache.useOfflineCache()
    })

    const onlineCleanup = ConnectionEventEmitter.on('online', () => {
      this.apolloOfflineCache.backOnline()
    })

    this.cleanupFns.push(onlineCleanup)
    this.cleanupFns.push(offlineCleanup)
  }

  setupFeatureFlagSubscription() {
    featureFlags.initializePromise.then(() => {
      const value = featureFlags.get('offline')

      if (value !== this.enabled) {
        if (value) {
          this.enable()
        } else {
          this.disable()
        }
      }

      this.cleanupFns.push(
        featureFlags.subscribe('offline', (isEnabled: boolean) => {
          if (isEnabled) {
            this.enable()
          } else {
            this.disable()
          }
        })
      )
    })
  }

  async enable() {
    if (!IS_SUPPORTED) {
      console.debug(
        'offlineMode - [OfflineManager] Offline mode is not supported'
      )
      return
    }
    if (this.enabled) {
      console.warn('Offline mode already enabled')
      return
    }

    const { user, status } = await apiFetchUser({
      returnOfflineUser: false,
    })
    if (status !== 'loggedIn') {
      console.warn('[OfflineManager] Offline mode user not logged in')
      return
    }

    await this.notifyCaches(true)

    this.store.dispatch(
      enableOfflineMode({ user, lastSynced: new Date().toISOString() })
    )
  }

  async disable() {
    if (!IS_SUPPORTED) {
      console.debug(
        'offlineMode - [OfflineManager] Offline mode is not supported'
      )
      return
    }
    if (!this.enabled) {
      console.warn('[OfflineManager] Offline mode already disabled')
      return
    }

    await this.notifyCaches(false)

    this.store.dispatch(disableOfflineMode())
  }

  async notifyCaches(enabled: boolean) {
    return Promise.all(
      enabled
        ? this.caches.map((cache) => cache.enable())
        : this.caches.map((cache) => cache.disable())
    )
  }

  cleanup() {
    while (this.cleanupFns.length > 0) {
      this.cleanupFns.pop()?.()
    }
  }
}

let offlineManager: OfflineManager

export const initOfflineMode = async () => {
  if (typeof window === 'undefined') {
    return
  }
  if (offlineManager) {
    throw new Error('OfflineManager already initialized')
  }

  try {
    offlineManager = new OfflineManager()
    await offlineManager.init()
  } catch (e) {
    console.error('Error initializing OfflineManager', e)
  }
}

if (typeof window !== 'undefined') {
  window['getOfflineManagerState'] = () => getStore().getState().OfflineManager
  window['getOfflineManager'] = () => offlineManager
}
