/**
 * Helper to quiet react dom warnings in the console during development. Code from https://github.com/facebook/react/issues/25447
 */
export const quieterConsole = () => {
  // Development React warnings in the console are really loud and it's hard to
  // see the actual logs you care about.
  //
  // This patches `console.error` to detect and reformat the logs.
  //
  // This should not show up in production builds.
  if (process.env.NODE_ENV === 'development') {
    const consoleError = console.error

    // Add a hidden filter in the output so that we can easily filter out the log
    // messages with the negative "-ReactDOMWarning" filter.
    const hiddenFilter = 'ReactDOMWarning'
    const hiddenStyles = 'color: transparent; font-size: 0px; user-select: none'

    console.error = (...args: Parameters<typeof consoleError>) => {
      // Fallback to normal console error unless this error came from react-dom.
      const trace = new Error().stack
      if (!trace || !trace.includes('react-dom')) {
        return consoleError(...args)
      }

      // All react-dom warnings start with "Warning:"
      const firstArg = args[0]
      if (typeof firstArg !== 'string' || !firstArg.startsWith('Warning:')) {
        return consoleError(...args)
      }

      // If we get here, we're reasonably sure that it's a ReactDOM warning.
      const template = args.shift()?.replace(/%s$/, '')
      const stacktrace = args.pop()

      console.groupCollapsed(
        `%c⚠️ ${template}%c${hiddenFilter}`,
        'color: red; font-weight: normal',
        ...args,
        hiddenStyles
      )
      console.log(
        `Tip: Add %c-${hiddenFilter}%c to the log Filter box to silence these ReactDOM error groups%c%{hiddenFilter}`,
        'font-weight: bold',
        'font-weight: normal',
        hiddenStyles
      )
      consoleError(`%s%c${hiddenFilter}`, stacktrace, hiddenStyles)
      console.groupEnd()
    }
  }
}
