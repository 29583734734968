import { useCallback, useRef } from 'react'

import { getApolloClient } from 'modules/api/apollo'
import { useOnConnectionEvent } from 'modules/connection/hooks/connectionHooks'
import { selectConnectionState } from 'modules/connection/reducer'
import { useAppStore } from 'modules/redux'

const refetchApiQueries = (reason: string) => {
  console.debug(
    `%c[useApiRefetch] Refetching due to ${reason}`,
    'background-color: aqua'
  )
  getApolloClient().reFetchObservableQueries()
}

const REFETCH_DELAY = 3000
/**
 * This hooks handles refetching of subscribed apollo graphql queries
 *
 * When the app is backgrounded / disconnected we tend to lose the active graphql subscriptions
 */
export const useApiRefetch = () => {
  const store = useAppStore()
  const shouldRefetchOnForeground = useRef(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const doRefetch = useCallback((reason: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }

    timeoutRef.current = setTimeout(() => {
      refetchApiQueries(reason)
      timeoutRef.current = null
    }, REFETCH_DELAY)
  }, [])

  const isBackgrounded = useCallback(() => {
    return selectConnectionState(store.getState()).backgrounded
  }, [store])

  useOnConnectionEvent(
    'online',
    useCallback(() => {
      if (isBackgrounded()) {
        shouldRefetchOnForeground.current = true
        return
      }
      doRefetch('online')
    }, [doRefetch, isBackgrounded])
  )
  useOnConnectionEvent(
    'wakeup',
    useCallback(() => {
      if (isBackgrounded()) {
        shouldRefetchOnForeground.current = true
        return
      }
      doRefetch('wakeup')
    }, [doRefetch, isBackgrounded])
  )
  useOnConnectionEvent(
    'foreground',
    useCallback(() => {
      if (shouldRefetchOnForeground.current) {
        doRefetch('foreground')
        shouldRefetchOnForeground.current = false
      }
    }, [doRefetch])
  )
}
