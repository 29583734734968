import { isLocalStorageSupported } from 'utils/storage/utils'

export const isOfflineEditingSupported = () => {
  if (typeof window === 'undefined') {
    return false
  }
  // check indexeddb and local storage are supported
  return (
    'indexedDB' in window && 'localStorage' in window && isLocalStorageSupported
  )
}

export const isOfflineModeSupported = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false
  }
  // check navigator has service worker, and offline editing is supported (ie, indexeddb and local storage are supported)
  return 'serviceWorker' in navigator && isOfflineEditingSupported()
}
