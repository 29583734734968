import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Code,
  Flex,
  FlexProps,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'

import SalParachute from 'publicImages/Sal-Asteroid-Parachute.png'
import { colorWithOpacity } from 'utils/color'

import { CenteredMessageCard } from './CenteredMessage'

const defaultError = (
  <>
    <Trans>Something went wrong</Trans>{' '}
    <span role="img" aria-label="sad face">
      😕
    </span>
  </>
)

type ErrorComponentProps = FlexProps & {
  errMessage?: JSX.Element | string
  starOverlay?: boolean
  showDisclosure?: boolean
}

export const ErrorComponent = ({
  errMessage = defaultError,
  showDisclosure = false,
  opacity = 1,
  backgroundColor = gammaTheme.colors.trueblue[900],
  ...flexProps
}: ErrorComponentProps) => {
  return (
    <Flex
      data-error-component
      w="100vw"
      h="100vh"
      overflow="hidden"
      justify="center"
      align="center"
      zIndex={99999}
      backgroundColor={colorWithOpacity(
        backgroundColor as string,
        opacity as number
      )}
      {...flexProps}
    >
      <CenteredMessageCard
        lede=""
        title={<Trans>Uh oh.</Trans>}
        stackProps={{ shadow: 'lg', position: 'relative' }}
      >
        <Stack spacing={8}>
          <Text>
            <Trans>
              It looks like something went wrong - try refreshing the page.
            </Trans>
          </Text>
          {showDisclosure && (
            <Accordion allowToggle pt={4}>
              <AccordionItem>
                <AccordionButton
                  px={0}
                  _hover={{ bgColor: 'none' }}
                  borderStyle={'none'}
                >
                  <Box flex="1" textAlign="left">
                    <Trans>Here are the details of what happened:</Trans>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Code colorScheme="red">{errMessage}</Code>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <Center>
            <Button
              variant="solid"
              size="lg"
              m={6}
              onClick={() => {
                window.location.reload()
              }}
            >
              <Trans>Refresh page</Trans>
            </Button>
          </Center>
        </Stack>
        <Image
          pos="absolute"
          w={36}
          bottom={'calc(-100% + 120px)'}
          right={-12}
          src={SalParachute.src}
          pointerEvents="none"
          opacity={{ base: 0, md: 1 }}
        />
      </CenteredMessageCard>
    </Flex>
  )
}
