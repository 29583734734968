import { debounce, memoize, without } from 'lodash'
import * as Y from 'yjs'

import { featureFlags } from 'modules/featureFlags/FeatureFlagProvider'
import { getStore } from 'modules/redux'
import {
  IndexeddbPersistence,
  clearDocument,
} from 'modules/tiptap_editor/CollaborativeEditor/y-indexeddb'
import { selectHocuspocusProviderDocId } from 'modules/tiptap_editor/reducer'

import { isOfflineEditingSupported } from '../compat'
import { YDOC_EXPIRATION_MS } from '../constants'
import {
  removeYDocSyncedDocs,
  selectOfflineModeEnabled,
  selectSyncedYDocs,
  setYdocSynced,
} from '../reducer'
import { OfflineCache } from './OfflineCache'

const IS_SUPPORTED = isOfflineEditingSupported()

export class YDocOfflineCache implements OfflineCache<any> {
  private store: ReturnType<typeof getStore> = getStore()

  async enable(): Promise<void> {
    // no-op
  }

  async disable(): Promise<void> {
    // no-op
  }

  get enabled() {
    return (
      IS_SUPPORTED &&
      (selectOfflineModeEnabled(this.store.getState()) ||
        featureFlags.get('offlineEditing'))
    )
  }

  /**
   * This is trying to setup the ydoc for loading from the cache
   */
  async loadYDoc(docId: string, ydoc: Y.Doc): Promise<boolean> {
    if (!this.enabled) {
      console.warn('Cannot setupForDocLoad when it is not enabled')
      return false
    }

    const docEntry = selectSyncedYDocs(this.store.getState())[docId]
    if (!docEntry) {
      // TODO(jordan) maybe cleanup the ydoc indexeddb cache here too?
      return false
    }

    // dont await the promise here, we need to know immediately if the ydoc
    // setup was successful
    this.setup(docId, ydoc)
    return true
  }

  async syncYDoc(docId: string, ydoc: Y.Doc): Promise<void> {
    if (!this.enabled) {
      console.warn('Cannot setup IndexedDB for doc as it is not enabled')
      return
    }

    await this.setup(docId, ydoc)

    this.store.dispatch(
      setYdocSynced({ docId, lastSynced: new Date().toISOString() })
    )
  }

  private setup(docId: string, ydoc: Y.Doc): Promise<void> {
    return new Promise<void>((resolve) => {
      const indexeddbPersistence = new IndexeddbPersistence(docId, ydoc)
      const handleUpdateDebounced = debounce(() => {
        const schemaVersion = ydoc
          .getMap('SCHEMA_VERSION')
          .get('REQUIRED_VERSION') as number | undefined
        indexeddbPersistence.set('lastUpdated', new Date().toISOString())
        indexeddbPersistence.set('schemaVersion', schemaVersion || '')
      }, 1000)
      ydoc.on('update', handleUpdateDebounced)
      indexeddbPersistence.on('synced', () => {
        resolve()
      })
    })
  }

  /**
   * It's really important not to call this function when an editor is open
   * we cannot destroy an open indexeddb database
   */
  async gc() {
    const now = Date.now()

    const docsToRemove = Object.values(this.syncedYDocs)
      .filter((doc) => {
        if (!doc.lastSynced) {
          return false
        }
        const lastSyncedTime = new Date(doc.lastSynced).getTime()
        return now - lastSyncedTime > YDOC_EXPIRATION_MS
      })
      .map((doc) => doc.docId)

    // its very important that we dont remove any docs that have an
    // active y-indexeddb connection, this will cause the editor to error
    const currentDocId = selectHocuspocusProviderDocId(this.store.getState())

    if (currentDocId) {
      this.removeDocs(without(docsToRemove, currentDocId))
    } else {
      this.removeDocs(docsToRemove)
    }
  }

  private async removeDocs(docIds: string[]) {
    if (docIds.length === 0) {
      return
    }
    this.store.dispatch(removeYDocSyncedDocs({ docIds: docIds }))
    const deleteIndexeddbPromises = docIds.map((id) => clearDocument(id))
    await Promise.all(deleteIndexeddbPromises)
  }

  async debug(): Promise<any> {
    return {
      syncedYdocs: this.syncedYDocs,
    }
  }

  private get syncedYDocs() {
    return selectSyncedYDocs(this.store.getState())
  }
}

// make singleton, so it can be accessed in hooks
export const getYDocOfflineCache = memoize(() => new YDocOfflineCache())
